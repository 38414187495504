import React from 'react';
// import Link from 'gatsby-link';
import CloudinaryVideo from "./cloudinaryvideoembed"
import Link from 'gatsby-link'

function HomepageNavMobile(homepagelinks) {
  console.log(homepagelinks);
  return (<div className="homepage-nav-mobile">
    <div className="homepage-nav-mobile__content">

      <CloudinaryVideo
        class_list="homepage-nav-mobile__bg-video cover-bg"
        publicId={`ykk/Home Page/Evolutionary_o6pxf4`}
        autoPlay={true}
        boomerangEffect={false}
      />
      <div class="image-filter-overlay"></div>
      <div className="homepage-nav-mobile__link-wrapper">
        {
          homepagelinks.homepagelinks.map((link) => {
            return (
              <div className="homepage-nav-mobile__item">
                <div className="reveal-text">
                  <div>
                    <Link className="h1 homepage-nav-mobile__title reveal-text__animate" to={`/${link.link.cached_url.replace("en/", "").replace("home", "")}`}>
                      <span>{link.title}</span>
                    </Link>
                  </div>
                </div>
              </div>

            )
          })
        }
      </div>
    </div>
  </div>);
}

export default HomepageNavMobile;
